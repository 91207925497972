import styled, { css } from 'styled-components';
import { Card, Result } from 'antd';

// CSS Styles
export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

// Styled Components
export const ResultPage = styled(Result)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CardContainer = styled(Card)`
  width: 100%;
  max-width: ${props => props.width}px;
  padding: ${props => props.padding || 32}px;
`;

// HTML Elements
export const ImagePlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.color || '#BFBFBF'};
`;
