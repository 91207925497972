import { Link, useNavigation, useSubmit } from 'react-router-dom';
import { App, Button, Divider, Flex, Form, Input, Layout, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { CardContainer } from '../styled';
import SubmitButton from '../components/SubmitButton';
import Auth from '../api/auth';

export default function SignIn() {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const { Title } = Typography;
  const { state } = useNavigation();
  const submit = useSubmit();
  const auth = new Auth();

  const rules = {
    email: [
      { required: true },
      { whitespace: true },
      { type: 'email' },
      { validator: (_, value) => (value.includes('stoicent.com') ? Promise.resolve() : Promise.reject(new Error('회사 이메일만 입력 가능합니다.'))) },
    ],
    passwordHash: [
      { required: true }, //
      { whitespace: true },
      { min: 6 },
    ],
  };

  const sendConfirmationLink = async email => {
    const sendSuccess = await auth.sendToken({ email });
    if (sendSuccess) modal.success({ content: '입력하신 이메일 주소로 인증 링크가 전송되었습니다.' });
    else modal.confirm({ content: '인증 링크 전송에 실패했습니다.', okText: '재전송', onOk: () => sendConfirmationLink(email) });
  };

  const handleSignIn = async values => {
    const response = await auth.signIn(values);
    const submitTarget = { queryKey: ['isSignedIn'] };
    const submitOptions = { method: 'post', action: '/', encType: 'application/json' };
    if (response.success) return submit(submitTarget, submitOptions);

    // 로그인에 실패한 경우
    const config = { type: 'error', content: null, onOk: null };
    switch (response.reason) {
      case 'UnregisteredUser':
        config.content = '등록되지 않은 사용자입니다.';
        break;
      case 'PasswordMismatch':
        config.content = '비밀번호가 일치하지 않습니다.';
        break;
      case 'EmailNotConfirmed':
        config.type = 'confirm';
        config.content = '이메일 인증이 완료되지 않았습니다. 지금 바로 진행하시겠습니까?';
        config.onOk = () => sendConfirmationLink(values.email);
        break;
      default:
        config.content = '알 수 없는 오류로 인해 로그인에 실패했습니다.';
        break;
    }

    // 원인에 따라 모달 띄우기
    modal[config.type](config);
  };

  return (
    <Layout>
      <Flex vertical justify="center" align="center" style={{ height: '100%' }}>
        <Title level={2}>로그인</Title>
        <CardContainer width={480}>
          <Form form={form} autoComplete="off" onFinish={handleSignIn}>
            <Form.Item name="email" rules={rules.email} messageVariables={{ label: '이메일 주소' }} validateFirst>
              <Input placeholder="이메일" prefix={<MailOutlined />} allowClear />
            </Form.Item>
            <Form.Item name="passwordHash" rules={rules.passwordHash} messageVariables={{ label: '비밀번호' }} validateFirst>
              <Input.Password type="password" placeholder="비밀번호" prefix={<LockOutlined />} allowClear />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <SubmitButton form={form} loading={state === 'submitting'}>
                  로그인
                </SubmitButton>
              )}
            </Form.Item>
          </Form>
          <Divider style={{ fontSize: 12 }}>또는</Divider>
          <Flex vertical justify="center" align="center" gap="middle">
            <Link to="/signup" children={<Button block>회원가입</Button>} style={{ width: '100%' }} />
            <Link to="/help">비밀번호를 잊어버리셨나요?</Link>
          </Flex>
        </CardContainer>
      </Flex>
    </Layout>
  );
}
