import { Link, useNavigate } from 'react-router-dom';
import { App, Flex, Form, Input, Layout, Typography, theme } from 'antd';
import { FormOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { CardContainer } from '../styled';
import SubmitButton from '../components/SubmitButton';
import Auth from '../api/auth';

export default function SignUp() {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const { token } = theme.useToken();
  const { Text, Title } = Typography;
  const auth = new Auth();
  const navigate = useNavigate();

  const checkIsCompanyEmail = (_, value) => {
    const isCompanyEmail = value.includes('stoicent.com');
    return isCompanyEmail ? Promise.resolve() : Promise.reject(new Error('회사 이메일로만 가입이 가능합니다.'));
  };

  const verifyEmail = async (_, value) => {
    const emailExists = await auth.checkEmail({ email: value });
    return emailExists ? Promise.reject(new Error('이미 가입된 이메일입니다.')) : Promise.resolve();
  };

  const verifyPassword = (_, value) => {
    if (form.getFieldValue('passwordHash') === value) return Promise.resolve();
    return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
  };

  const sendConfirmationLink = async email => {
    const sendSuccess = await auth.sendToken({ email });
    if (!sendSuccess) return modal.confirm({ content: '인증 링크 전송에 실패했습니다.', okText: '재전송', onOk: () => sendConfirmationLink(email) });
    modal.success({ content: '입력하신 이메일 주소로 인증 링크가 전송되었습니다. 링크를 클릭하여 가입을 완료하세요.' });
    navigate('/signin', { replace: true });
  };

  const handleSignUp = values => {
    modal.confirm({
      icon: <FormOutlined style={{ color: token.colorPrimary }} />,
      content: '회원가입을 신청하시겠습니까?',
      onOk: async () => {
        const signUpSuccess = await auth.signUp({ ...values });
        if (signUpSuccess) sendConfirmationLink(values.email);
        else modal.error({ content: '가입 신청 중 오류가 발생했습니다.' });
      },
    });
  };

  // Form validate rules
  const rules = {
    username: [{ required: true }, { whitespace: true }, { min: 2 }],
    email: [{ required: true }, { whitespace: true }, { type: 'email' }, { validator: checkIsCompanyEmail }, { validator: verifyEmail }],
    passwordHash: [{ required: true }, { whitespace: true }, { min: 6 }],
    verify: [{ required: true, message: '비밀번호가 일치하지 않습니다.' }, { validator: verifyPassword }],
  };

  return (
    <Layout>
      <Flex vertical justify="center" align="center" gap="large" style={{ height: '100%' }}>
        <Title level={2}>회원가입</Title>
        <CardContainer width={480}>
          <Form form={form} autoComplete="off" onFinish={handleSignUp}>
            <Form.Item //
              name="username"
              rules={rules.username}
              messageVariables={{ label: '이름' }}
              validateDebounce={500}
              validateFirst
              hasFeedback
            >
              <Input placeholder="이름" prefix={<UserOutlined />} allowClear />
            </Form.Item>
            <Form.Item //
              name="email"
              rules={rules.email}
              messageVariables={{ label: '이메일 주소' }}
              validateDebounce={500}
              validateFirst
              hasFeedback
            >
              <Input placeholder="이메일" prefix={<MailOutlined />} allowClear />
            </Form.Item>
            <Form.Item
              name="passwordHash"
              rules={rules.passwordHash}
              messageVariables={{ label: '비밀번호' }}
              validateDebounce={500}
              validateFirst
              hasFeedback
            >
              <Input.Password type="password" placeholder="비밀번호" prefix={<LockOutlined />} allowClear />
            </Form.Item>
            <Form.Item //
              name="verify"
              dependencies={['passwordHash']}
              rules={rules.verify}
              validateDebounce={500}
              validateFirst
              hasFeedback
            >
              <Input.Password type="password" placeholder="비밀번호 확인" prefix={<LockOutlined />} allowClear />
            </Form.Item>
            <Form.Item shouldUpdate>{() => <SubmitButton form={form}>가입 신청</SubmitButton>}</Form.Item>
          </Form>
          <Flex justify="center" gap="small">
            <Text>이미 가입하셨나요?</Text>
            <Link to="/signin">로그인</Link>
          </Flex>
        </CardContainer>
      </Flex>
    </Layout>
  );
}
