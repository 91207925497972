import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Badge, Card, Flex, Input, List, Spin, Typography } from 'antd';
import Crawler from '../api/crawler';

export default function Discord() {
  const { pageNum } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const { isLoading, data: discordAppList } = useQuery(['discord'], new Crawler().getDiscordAppList, { staleTime: 1000 * 6 * 5 });
  const { Title } = Typography;
  const navigate = useNavigate();

  useEffect(() => setDataSource(discordAppList), [discordAppList]);

  const pagination = {
    align: 'center',
    pageSize: 20,
    current: parseInt(pageNum),
    onChange: page => navigate(`/discord/${page}`),
  };

  const handleSearch = inputValue => {
    const searchResult = discordAppList.filter(row => {
      const pattern = /[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]/g;
      const regex = new RegExp(inputValue.replace(pattern, '').split('').join('.*'), 'i');
      return regex.test(row.guildName) || regex.test(row.channelName);
    });
    setDataSource(searchResult);
  };

  return (
    <Spin spinning={isLoading} tip="로딩 중입니다...">
      <Flex vertical>
        <Title level={3}>Discord 채팅 분석 대상 목록</Title>
        <Card>
          <Flex vertical gap="middle">
            <Badge count={dataSource?.length} style={{ zIndex: 10 }} showZero>
              <Input.Search
                placeholder="길드 및 채널명으로 검색"
                onChange={e => handleSearch(e.target.value)}
                onSearch={handleSearch}
                enterButton
                allowClear
              />
            </Badge>
            <List
              size="small"
              grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }}
              pagination={pagination}
              dataSource={dataSource}
              renderItem={(item, index) => {
                const orderedTitle = `${(pagination.current - 1) * pagination.pageSize + index + 1}. ${item.guildName}`;
                return (
                  <List.Item style={{ padding: 2, margin: 4 }}>
                    <Card
                      title={orderedTitle}
                      bodyStyle={{ padding: 16 }}
                      actions={[<Link to={`/discord/details/${item.guildID}`} children="데이터 통계 보기" />]}
                    >
                      <Card.Meta
                        avatar={<Avatar src={`https://cdn.discordapp.com/icons/${item.guildID}/${item.iconUrl}.png`} size={80} />}
                        description={item.channelName}
                      />
                    </Card>
                  </List.Item>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Spin>
  );
}
