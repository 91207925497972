import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { App, Avatar, Card, Flex, Input, List, Segmented, Spin, Typography } from 'antd';
import { BsDiscord, BsSteam } from 'react-icons/bs';
import SteamProfile from '../components/SteamProfile';
import DiscordProfile from '../components/DiscordProfile';
import Crawler from '../api/crawler';

export default function Register() {
  const [type, setType] = useState('steam');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const { modal } = App.useApp();
  const { Text, Title } = Typography;
  const queryClient = useQueryClient();

  const options = [
    {
      value: 'steam',
      label: (
        <Flex vertical justify="center" align="center" gap="small" style={{ padding: 8 }}>
          <Avatar icon={<BsSteam />} />
          <Text>스팀</Text>
        </Flex>
      ),
    },
    {
      value: 'discord',
      label: (
        <Flex vertical justify="center" align="center" gap="small" style={{ padding: 8 }}>
          <Avatar icon={<BsDiscord />} />
          <Text>디스코드</Text>
        </Flex>
      ),
    },
  ];

  const handleTypeChange = value => {
    setType(value);
    setSearchResult([]);
  };

  const stopLoadingAndShowModal = (modalType, modalContent) => {
    setLoading(false);
    modal[modalType]({ content: modalContent });
  };

  const extractParams = url => {
    const match = url.match(/\d+/g);
    switch (type) {
      case 'steam':
        return match ? { appId: match[0] } : null;
      case 'discord':
        return match.length === 2 ? { guildId: match[0], channelId: match[1] } : null;
      default:
        return url;
    }
  };

  const handleSearch = async url => {
    setLoading(true);

    const params = extractParams(url);
    if (!params) {
      stopLoadingAndShowModal('warning', 'URL 형식이 유효하지 않습니다.');
      return;
    }

    const crawler = new Crawler();
    const foundApp = await queryClient.ensureQueryData([type, params], () => crawler.checkUrl(type, params), { staleTime: 1000 * 6 * 5 });
    if (!foundApp.urlExists) {
      const reason = type === 'steam' ? 'Steam 공식 사이트에 등록된 앱이 아닙니다.' : 'Discord 서버가 존재하지 않습니다.';
      setSearchResult([]);
      stopLoadingAndShowModal('error', reason);
      return;
    }

    setSearchResult([foundApp.result]);
    stopLoadingAndShowModal('success', '검색이 완료되었습니다.');
  };

  return (
    <Flex vertical>
      <Title level={3}>스크래핑 / 분석 대상 게임 등록</Title>
      <Spin spinning={loading} tip="검색 중입니다...">
        <Card>
          <Flex vertical gap="large">
            <Segmented block options={options} value={type} onChange={handleTypeChange} />
            <div>
              <Title level={5}>{type === 'steam' ? '스팀' : '디스코드'} 주소로 검색</Title>
              <Input.Search
                placeholder={
                  type === 'steam' ? 'https://store.steampowered.com/app/앱_아이디/앱_이름' : 'https://discord.com/channels/길드_아이디/채널_아이디'
                }
                loading={loading}
                onSearch={handleSearch}
                enterButton
              />
            </div>
            <div>
              <Title level={5}>검색 결과</Title>
              <List
                bordered
                dataSource={searchResult}
                renderItem={item => (type === 'steam' ? <SteamProfile app={item} /> : <DiscordProfile app={item} />)}
              />
            </div>
          </Flex>
        </Card>
      </Spin>
    </Flex>
  );
}
