import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { convertDateFormat } from '../utils/date';
import { Button, Flex } from 'antd';
import Crawler from '../api/crawler';

export default function ExcelButton({ type, dataSource, fileName }) {
  const crawler = new Crawler();

  const handleDownload = () => {
    crawler.downloadToExcel(type, dataSource).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}_${convertDateFormat(new Date(), 'YYYYMMDD')}.xlsx`;
      a.click();
    });
  };

  return (
    <Button onClick={handleDownload}>
      <Flex justify="center" align="center" gap={4}>
        엑셀 저장
        <PiMicrosoftExcelLogoFill fontSize={20} />
      </Flex>
    </Button>
  );
}
