import { useLocation, useParams } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import SteamTable from '../components/SteamTable';

export default function SteamDetail() {
  const { appID } = useParams();
  const { title, imagePath } = useLocation().state;
  const { Link, Title } = Typography;
  const steamLink = `https://store.steampowered.com/app/${appID}`;

  return (
    <Flex vertical gap="middle">
      <Flex align="center" gap="middle">
        <img src={`${process.env.REACT_APP_BASE_URL}/${imagePath}`} alt={title} width={200} height={94} />
        <div>
          <Title level={3}>{title}</Title>
          <Link children={steamLink} href={steamLink} target="_blank" type="secondary" />
        </div>
      </Flex>
      <SteamTable type="app" params={appID} excelButton />
    </Flex>
  );
}
