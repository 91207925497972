import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, DatePicker, Flex, Space, Typography } from 'antd';
import { convertDateFormat, toDayjs } from '../utils/date';
import MetaTopSellingList from '../components/MetaTopSellingList';
import styled from 'styled-components';

const AbsoluteSpace = styled(Space)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export default function Meta() {
  const ISO8601 = 'YYYY-MM-DD';
  const today = convertDateFormat(new Date(), ISO8601);
  const [dateParams, setDateParams] = useSearchParams({ date: today });
  const targetDate = dateParams.get('date') || today;
  const { Title } = Typography;
  const navigate = useNavigate();

  const handleDateChange = (action, selectedDate) => {
    const dateString = selectedDate || convertDateFormat(toDayjs(targetDate)[action](1, 'day'), ISO8601);
    setDateParams({ date: dateString });
    navigate(`/meta/1?date=${dateString}`);
  };

  return (
    <Flex vertical>
      <Title level={3}>Meta 유료 게임 최다 판매 TOP50</Title>
      <Card>
        <AbsoluteSpace>
          <DatePicker
            defaultValue={toDayjs(targetDate, ISO8601)}
            value={toDayjs(targetDate, ISO8601)}
            onChange={handleDateChange}
            allowClear={false}
          />
          <Button onClick={() => setDateParams({ date: today })}>초기화</Button>
        </AbsoluteSpace>
        <MetaTopSellingList targetDate={targetDate} />
      </Card>
    </Flex>
  );
}
