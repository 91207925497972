import { Button, Flex, Image, Popover, Space, Table, Tag, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const EmojiTag = styled(Tag)`
  display: block;
  text-align: center;
  padding: 2px 6px;
  font-size: 14px;
`;

export default function DiscordTable({ dataSource }) {
  const { Text } = Typography;

  const popoverInnerStyle = {
    maxWidth: 720,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  };

  const pagination = {
    size: 'large',
    position: ['bottomCenter'],
    showSizeChanger: false,
  };

  const columns = [
    {
      title: '공지 날짜',
      dataIndex: 'timestamp',
      align: 'center',
    },
    {
      title: '공지 내용',
      dataIndex: 'content',
      align: 'center',
      render: content =>
        content ? (
          <Popover content={<ReactMarkdown children={content} />} trigger="click" placement="right" overlayInnerStyle={popoverInnerStyle}>
            <Button type="text" size="small" margin="auto">
              <Flex justify="center" align="center" gap={4}>
                <span>{content.trim().slice(0, 20)}...</span>
                <SearchOutlined style={{ fontSize: 16 }} />
              </Flex>
            </Button>
          </Popover>
        ) : (
          <Text type="secondary">&lt;이미지&gt;</Text>
        ),
    },
  ];

  for (let i = 0; i < 20; i++) {
    columns.push({
      title: `반응${i + 1}`,
      align: 'center',
      render: (_, { reactions }) =>
        reactions[i] ? (
          <EmojiTag>
            <Space size={6}>
              <Image src={reactions[i].imageUrl} width={16} height={16} preview={false} />
              <span>{reactions[i].count.toLocaleString()}</span>
            </Space>
          </EmojiTag>
        ) : null,
    });

    if (i + 1 === 20) {
      columns.push({
        title: '반응 총합',
        align: 'center',
        fixed: 'right',
        render: (_, record) => record.reactions.reduce((result, emoji) => result + emoji.count, 0).toLocaleString(),
      });
    }
  }

  return (
    <Table
      bordered
      size="middle"
      dataSource={dataSource}
      columns={columns}
      rowKey={record => record.messageID}
      pagination={pagination}
      scroll={{ x: 'max-content' }}
    />
  );
}
