import { App, Button, List } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IoLogoDiscord } from 'react-icons/io5';
import Crawler from '../api/crawler';

export default function DiscordProfile({ app }) {
  const {
    guild: { id: guildId, name: guildName },
    channel: { id: channelId, name: channelName },
  } = app;
  const { modal, notification } = App.useApp();

  const showRegisterModal = () => {
    modal.confirm({
      icon: <IoLogoDiscord size={24} color="#5765F2" className="anticon" />,
      title: '채널을 등록하시겠습니까?',
      content: '등록 즉시 채널 내의 모든 메시지를 분석하며, 해당 작업은 최대 5분 정도 소요될 수 있습니다.',
      onOk: () => {
        handleRegister();
        notification.open({ icon: <LoadingOutlined spin />, message: '채널을 등록하는 중입니다...' });
      },
    });
  };

  const handleRegister = () => {
    const crawler = new Crawler();
    const params = { guildId, guildName, channelId, channelName };
    crawler.register('discord', params).then(success => {
      success
        ? notification.success({ message: '채널이 성공적으로 등록되었습니다.' })
        : notification.error({ message: '이미 등록되어 있는 채널입니다.' });
    });
  };

  return (
    <List.Item
      extra={
        <Button type="primary" onClick={showRegisterModal}>
          채널 등록하기
        </Button>
      }
    >
      <List.Item.Meta title={guildName} description={channelName} />
    </List.Item>
  );
}
