import { Outlet } from 'react-router-dom';
import { App as AppProvider, ConfigProvider, Spin, theme } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import koKR from 'antd/locale/ko_KR';
import Auth from './api/auth';

export const authQuery = () => ({
  queryKey: ['isSignedIn'],
  queryFn: () => new Auth().status(),
  staleTime: 1000 * 6 * 5,
});

export const loader = queryClient => () => {
  const query = authQuery();
  return queryClient.ensureQueryData(query.queryKey, query.queryFn);
};

export const action = queryClient => async submit => {
  const queryKey = await submit.request.json();
  return queryClient.invalidateQueries(queryKey);
};

export default function Root() {
  const { token } = theme.useToken();

  // Global config
  // Spin
  Spin.setDefaultIndicator(<LoadingOutlined />);

  // Form
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label}을(를) 입력해 주세요.',
    whitespace: '공백만 입력할 수 없습니다.',
    string: {
      min: '${label}은(는) 최소 ${min}글자 이상이어야 합니다.',
      max: '${label}은(는) 최대 ${min}글자까지 입력 가능합니다.',
    },
    types: {
      email: '이메일 주소 형식이 올바르지 않습니다.',
    },
  };

  return (
    <ConfigProvider
      locale={koKR}
      theme={{
        token: {
          colorPrimary: '#3f9dff',
          borderRadius: 0,
          fontFamily: 'NanumSquareNeo-Variable',
        },
        components: {
          Layout: {
            bodyBg: token.colorWhite,
            headerBg: token.colorBgBlur,
            siderBg: token.colorBgBlur,
          },
          Menu: {
            itemBg: token.colorBgBlur,
            darkItemBg: token.colorBgBlur,
          },
        },
      }}
      form={{ validateMessages }}
      popupMatchSelectWidth={false}
    >
      <AppProvider>
        <Outlet />
      </AppProvider>
    </ConfigProvider>
  );
}
