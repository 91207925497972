import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, DatePicker, Flex, Space, Typography } from 'antd';
import { convertDateFormat, toDayjs } from '../utils/date';
import SteamTable from '../components/SteamTable';
import SteamAppList from '../components/SteamAppList';

export default function Steam({ mode }) {
  const format = 'YYYY-MM-DD';
  const today = convertDateFormat(new Date(), format);
  const [dateParams, setDateParams] = useSearchParams({ date: today });
  const { Title } = Typography;

  const targetDate = dateParams.get('date') || today;
  const dateValue = toDayjs(targetDate, format);
  const navigate = useNavigate();

  const handleModeChange = value => navigate(`/steam/${value}/1`);

  const handleDateChange = (_, dateString) => {
    setDateParams({ date: dateString });
    navigate(`/steam/daily/1?date=${dateString}`);
  };

  const tabList = [
    {
      key: 'app',
      label: '게임별',
    },
    {
      key: 'daily',
      label: '날짜별',
    },
  ];

  const contentList = {
    app: <SteamAppList />,
    daily: <SteamTable type="daily" params={targetDate} />,
  };

  return (
    <Flex vertical>
      <Title level={3}>Steam 스크래핑 대상 목록</Title>
      <Card
        bodyStyle={{ paddingBlock: 16 }}
        tabList={tabList}
        activeTabKey={mode}
        onTabChange={handleModeChange}
        tabProps={{ size: 'middle' }}
        tabBarExtraContent={
          mode === 'daily' && (
            <Space>
              <DatePicker defaultValue={dateValue} value={toDayjs(dateValue)} onChange={handleDateChange} allowClear={false} />
              <Button onClick={() => setDateParams({ date: today })}>초기화</Button>
            </Space>
          )
        }
      >
        {contentList[mode]}
      </Card>
    </Flex>
  );
}
