import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Root, { loader as rootLoader, action as rootAction } from './Root';
import Index from './routes/Index';
import SignIn from './routes/SignIn';
import SignUp from './routes/SignUp';
import Help from './routes/Help';
import Password from './routes/Password';
import Register from './routes/Register';
import Steam from './routes/Steam';
import SteamDetail from './routes/SteamDetail';
import Discord from './routes/Discord';
import DiscordDetail from './routes/DiscordDetail';
import Meta from './routes/Meta';

import AuthRoutes from './components/AuthRoutes';
import MainLayout from './components/MainLayout';
import './index.css';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <Root />,
    errorElement: <Navigate replace to="/" />,
    loader: rootLoader(queryClient),
    action: rootAction(queryClient),
    children: [
      {
        element: <AuthRoutes access="public" />,
        children: [
          { path: '/signin', element: <SignIn /> },
          { path: '/signup', element: <SignUp /> },
          { path: '/help', element: <Help /> },
        ],
      },
      {
        element: <AuthRoutes access="private" />,
        children: [
          {
            element: <MainLayout />,
            children: [
              // Home
              { index: true, element: <Index /> },

              // User
              { path: '/password', element: <Password /> },

              // Crawling
              { path: '/register', element: <Register /> },
              { path: '/steam/app/:pageNum', element: <Steam mode="app" /> },
              { path: '/steam/daily/:pageNum', element: <Steam mode="daily" /> },
              { path: '/steam/app/details/:appID', element: <SteamDetail /> },
              { path: '/discord/:pageNum', element: <Discord /> },
              { path: '/discord/details/:guildID', element: <DiscordDetail /> },
              { path: '/meta/:pageNum', element: <Meta /> },

              // Navigate
              { path: '/steam', element: <Navigate replace to="/steam/daily/1" /> },
              { path: '/steam/daily', element: <Navigate replace to="/steam/daily/1" /> },
              { path: '/steam/app', element: <Navigate replace to="/steam/app/1" /> },
              { path: '/discord', element: <Navigate replace to="/discord/1" /> },
              { path: '/meta', element: <Navigate replace to="/meta/1" /> },
            ],
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
