import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { App, Badge, Card, Descriptions, Flex, Image, Input, List, Space, Tag, Typography } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { convertDateFormat } from '../utils/date';
import { ImagePlaceHolder } from '../styled';
import Crawler from '../api/crawler';
import IntlFormatter from '../utils/intl';

export default function SteamAppList() {
  const [dataSource, setDataSource] = useState([]);
  const { isLoading, data: appList } = useQuery(['steam'], new Crawler().getSteamAppList, { staleTime: 1000 * 6 * 5 });
  const { pageNum } = useParams();
  const { modal } = App.useApp();
  const { Text } = Typography;

  useEffect(() => setDataSource(appList), [appList]);

  const pagination = {
    align: 'center',
    pageSize: 20,
    current: parseInt(pageNum),
    showSizeChanger: false,
    itemRender: (page, type, originalElement) => (type === 'page' ? <Link to={`/steam/app/${page}`}>{page}</Link> : originalElement),
  };

  const handleSearch = inputValue => {
    const searchResult = appList?.filter(app => {
      const pattern = /[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]/g;
      const regex = new RegExp(inputValue.replace(pattern, '').split('').join('.*'), 'i');
      return regex.test(app.title) || regex.test(app.subTitle);
    });
    setDataSource(searchResult);
  };

  const showDetailModal = async appID => {
    const recentData = appList?.find(data => data.appID === appID);
    const { title, imagePath, allReviews, recentReviews, releaseDate, developer, publisher1, publisher2, device, price } = recentData;

    const popularUserTags = [];
    for (let i = 0; i < 5; i++) {
      popularUserTags.push(recentData[`popularUserTag${i + 1}`]);
    }
    const items = [
      {
        span: 3,
        label: '최근 리뷰',
        children: recentReviews || '- (0)',
      },
      {
        span: 3,
        label: '전체 리뷰',
        children: allReviews,
      },
      {
        span: 3,
        label: '출시 날짜',
        children: convertDateFormat(releaseDate, 'YYYY-MM-DD'),
      },
      {
        span: 3,
        label: '개발사',
        children: developer,
      },
      {
        span: 3,
        label: '퍼블리싱',
        children: [publisher1, publisher2].filter(publisher => publisher !== null).join(','),
      },
      {
        span: 3,
        label: '기기',
        children: device,
      },
      {
        span: 3,
        label: '가격',
        children: recentReviews || price ? new IntlFormatter().getPriceByCountry(price) : 'Free',
      },
    ];

    modal.info({
      width: 'min-content',
      title: 'Steam 게임 프로필 정보',
      content: (
        <Flex vertical gap="middle">
          <Image src={`${process.env.REACT_APP_BASE_URL}/${imagePath}`} width={350} preview={false} />
          <Descriptions size="small" title={title} items={items} labelStyle={{ width: '25%' }} contentStyle={{ whiteSpace: 'pre-wrap' }} />
          <Space wrap size={2}>
            {popularUserTags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Space>
        </Flex>
      ),
      closable: true,
    });
  };

  return (
    <Flex vertical gap="middle">
      <Badge count={dataSource?.length} showZero style={{ zIndex: 10 }}>
        <Input.Search placeholder="게임명으로 검색" onSearch={handleSearch} enterButton allowClear />
      </Badge>
      <List
        size="small"
        grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }}
        dataSource={dataSource}
        pagination={pagination}
        loading={{ spinning: isLoading, tip: '로딩 중입니다...' }}
        renderItem={(item, index) => {
          const { scrapingDate, appID, title, imagePath, developer } = item;
          const steamLink = `https://store.steampowered.com/app/${appID}`;
          const detailLink = `/steam/app/details/${appID}`;
          const thumbnailSrc = `${process.env.REACT_APP_BASE_URL}/${imagePath}`;
          const orderedTitle = `${(pagination.current - 1) * pagination.pageSize + index + 1}. ${title}`;
          return (
            <List.Item style={{ padding: 2, margin: 4 }}>
              <Card
                bodyStyle={{ padding: 16 }}
                cover={<Image src={thumbnailSrc} preview={false} placeholder={<ImagePlaceHolder />} />}
                actions={[
                  <Link children="프로필 정보" onClick={() => showDetailModal(appID)} />,
                  <Link children="전체 데이터" to={detailLink} state={item} />,
                ]}
              >
                <Card.Meta
                  title={<a children={orderedTitle} href={steamLink} target="_blank" rel="noreferrer" style={{ color: 'inherit' }} />}
                  description={
                    <Space direction="vertical">
                      <Text>{developer}</Text>
                      <Space>
                        <Space size={4}>
                          <CalendarOutlined />
                          {convertDateFormat(scrapingDate, 'YYYY-MM-DD')}
                        </Space>
                        <Flex gap={4}>
                          <ClockCircleOutlined />
                          {convertDateFormat(scrapingDate, 'HH:mm')}
                        </Flex>
                      </Space>
                    </Space>
                  }
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </Flex>
  );
}
