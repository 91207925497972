import { Button } from 'antd';

export default function SubmitButton({ form, target, ...props }) {
  let disabled;

  if (target) {
    disabled = !form.isFieldTouched(target) || form.isFieldValidating(target) || !!form.getFieldError(target).length;
  } else {
    disabled =
      !Object.keys(form.getFieldsValue(true)).length ||
      !form.isFieldsTouched(true) ||
      form.isFieldsValidating() ||
      !!form.getFieldsError().filter(({ errors }) => errors.length).length;
  }

  return <Button block type="primary" htmlType="submit" disabled={disabled} {...props} />;
}
