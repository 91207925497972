import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

export const dayjsInstance = dayjs;

export const toDayjs = dateString => {
  if (!dateString) return null;
  return dayjs(dateString);
};

export const convertDateFormat = (dateString, format) => {
  if (!dateString) return null;
  return dayjs(dateString).format(format);
};
