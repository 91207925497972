import { App, Button, Image, List, Space, Typography } from 'antd';
import Crawler from '../api/crawler';
import IntlFormatter from '../utils/intl';

export default function SteamProfile({ app }) {
  const { appID, title, imagePath, developer, price } = app;
  const { modal } = App.useApp();
  const { Text } = Typography;

  const handleRegister = async () => {
    const crawler = new Crawler();
    modal.confirm({
      icon: null,
      content: '앱을 등록하시겠습니까?',
      onOk: () =>
        crawler
          .register('steam', { appID, appName: title, isTarget: true })
          .then(success =>
            success ? modal.success({ content: '등록이 완료되었습니다.' }) : modal.warning({ content: '이미 등록되어 있는 앱입니다.' })
          ),
    });
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Image src={imagePath} width={240} height={112} preview={false} />}
        title={title}
        description={
          <Space direction="vertical">
            <Text>{developer}</Text>
            <Text>{price ? new IntlFormatter().getPriceByCountry(price) : 'Free'}</Text>
          </Space>
        }
      />
      <Button type="primary" onClick={handleRegister}>
        등록하기
      </Button>
    </List.Item>
  );
}
