export default class IntlFormatter {
  constructor(locale) {
    this.locale = locale || process.env.REACT_APP_CURRENCY_CODE;
  }

  get #currencyCode() {
    switch (this.locale) {
      case 'ko-KR':
        return 'KRW';
      case 'en-US':
        return 'USD';
      default:
        return 'KRW';
    }
  }

  getPriceByCountry(price) {
    return new Intl.NumberFormat(this.locale, { style: 'currency', currency: this.#currencyCode }).format(price);
  }
}
