import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Avatar, Card, DatePicker, Divider, Skeleton, Space, Spin, Typography } from 'antd';
import DiscordExporter from '../api/discordExporter';
import DiscordChat from '../api/discordChat';
import DiscordTable from '../components/DiscordTable';
import DiscordChart from '../components/DiscordChart';

export default function DiscordDetail() {
  const [year, setYear] = useState('');
  const { guildID } = useParams();
  const { isLoading, error, data } = useQuery(['discord', guildID], () => new DiscordExporter().getJson(guildID), { staleTime: 1000 * 6 * 5 });
  const { Text, Title } = Typography;

  // 데이터 결합
  // 나머지 속성은 하나만 남겨두고 messages 배열만 allMessages로 대체
  const allMessages = [];
  const combinedData = data?.reduce((result, obj) => {
    if (obj.messages) {
      obj.messages.forEach(message => {
        // 동일한 id를 가진 메시지가 이미 존재하는 경우, 해당 인덱스의 메시지를 덮어씌움
        const oldMessageIndex = allMessages.findIndex(existing => existing.id === message.id);
        if (oldMessageIndex !== -1) allMessages[oldMessageIndex] = message;
        else allMessages.push(message);
      });
    }
    return { ...result, ...obj, messageCount: allMessages.length, messages: allMessages };
  }, {});

  const discordChat = new DiscordChat(combinedData);
  const { guild, channel, messages } = discordChat;

  const messageList = discordChat.yearly.find(chat => chat.year === year)?.messages ?? discordChat.monthly;
  const yearData = discordChat.yearly.map(chat => chat.year);

  const handlePickerChange = (date, dateString) => setYear(date?.year().toString() ?? dateString);
  const disabledDate = current => !yearData.includes(current.year().toString());

  if (error) return 'Error...';

  return (
    <Spin spinning={isLoading} size="large" tip="로딩 중입니다...">
      <Skeleton active loading={isLoading} avatar={{ shape: 'square', size: 88 }} title={{ width: 200 }} paragraph={{ rows: 1, width: 400 }}>
        <Space size="middle">
          <Avatar src={guild.iconUrl} shape="square" size={88} />
          <Space direction="vertical">
            <Title level={3}>{guild.name}</Title>
            <Text strong>
              {channel.category} / {channel.name}
            </Text>
          </Space>
        </Space>
      </Skeleton>
      <Divider />
      <Title level={4}>공지별 반응 총합</Title>
      <DiscordTable dataSource={messages} />
      <Divider />
      <Title level={4}>유저 반응 집계 (월 평균)</Title>
      <Card>
        <DatePicker picker="year" format="YYYY년" placeholder="연도 선택" disabledDate={disabledDate} onChange={handlePickerChange} />
        <DiscordChart dataSource={messageList} />
      </Card>
      <Divider />
    </Spin>
  );
}
