import axios from 'axios';

export default class Crawler {
  #client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  // Public
  checkUrl = async (type, params) => {
    return this.#client
      .post(`/${type}/check`, params)
      .then(response => response.data)
      .catch(error => console.error(`Failed to check URL: ${error.message}`));
  };

  register = async (type, params) => {
    return this.#client
      .post(`/${type}/register`, params)
      .then(response => response.data.success)
      .catch(error => console.error(`Failed to register app: ${error.message}`));
  };

  downloadToExcel = async (type, params) => {
    return this.#client //
      .post(`/${type}/excel`, params, { responseType: 'blob' })
      .then(response => response.data)
      .catch(error => console.error(`Failed to download excel file: ${error.message}`));
  };

  // Steam
  getSteamAppList = async () => {
    return this.#client //
      .get('/steam')
      .then(response => response.data)
      .catch(error => console.error(`Failed to get app list: ${error.message}`));
  };

  getSteamCrawlingList = async (type, params) => {
    return this.#client //
      .get(`/steam/${type}/${params}`)
      .then(response => response.data)
      .catch(error => console.error(`Failed to get app detail: ${error.message}`));
  };

  // Meta
  getMetaTopSellingList = async targetDate => {
    return this.#client
      .post('/meta', targetDate)
      .then(response => response.data)
      .catch(error => console.error(`Failed to get meta list: ${error.message}`));
  };

  // Discord
  getDiscordAppList = async () => {
    return this.#client //
      .get('/discord')
      .then(response => response.data)
      .catch(error => console.error(`Failed to get app list: ${error.message}`));
  };
}
