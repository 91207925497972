import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Flex, Image, Input, Popover, Rate, Space, Table, Tag, Typography } from 'antd';
import { CrownFilled, DislikeFilled, LikeFilled, MessageFilled, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { ImagePlaceHolder } from '../styled';
import { convertDateFormat } from '../utils/date';
import Crawler from '../api/crawler';
import IntlFormatter from '../utils/intl';
import ExcelButton from './ExcelButton';

export default function SteamTable({ type, params }) {
  const crawler = new Crawler();
  const { isLoading, data: crawlingList } = useQuery(['steam', params], () => crawler.getSteamCrawlingList(type, params), {
    enabled: !!params,
    staleTime: 1000 * 6 * 5,
  });

  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { appID, pageNum } = useParams();
  const { Text } = Typography;
  const navigate = useNavigate();

  useEffect(() => setDataSource(crawlingList), [crawlingList]);

  const getTagColor = string => {
    if (!string) return;
    const lowerString = string.toLowerCase().replace(/[-/\s]+/g, ' ');
    switch (lowerString) {
      case 'major update':
        return 'gold'; // #f5deb3
      case 'regular update':
        return 'blue'; // #5fa3d3
      case 'small update':
        return 'default'; // #909da7
      case 'news':
        return 'orange'; // #b0ad92
      case 'in game event':
        return 'lime'; // #d3dfa9
      case 'live stream broadcast':
        return 'lime'; // #d3dfa9
      case 'game release':
        return 'blue'; // #5fa3d3
      case 'dlc release':
        return 'purple'; // #a380a8
      default:
        if (lowerString.includes('positive')) return 'geekblue';
        else if (lowerString.includes('mixed')) return 'orange';
        else if (lowerString.includes('negative')) return 'red';
        else return 'default'; // #909da7
    }
  };

  const getStarValue = rating => {
    if (!rating) return 0;
    switch (rating.toLowerCase()) {
      case 'overwhelmingly positive':
        return 5;
      case 'very positive':
        return 4.5;
      case 'positive':
        return 4;
      case 'mostly positive':
        return 3.5;
      case 'mixed':
        return 3;
      case 'mostly negative':
        return 2.5;
      case 'negative':
        return 2;
      case 'very negative':
        return 1.5;
      case 'overwhelmingly negative':
        return 1;
      default:
        return 0;
    }
  };

  const getRateUI = reviews => {
    const match = reviews?.match(/^(.*?)\s+(\(.*\))$/);
    return (
      <Space direction="vertical" size={1}>
        <Rate defaultValue={getStarValue(match?.[1])} allowHalf disabled />
        <Space size={4}>
          {match ? <Tag color={getTagColor(reviews)}>{match[1]}</Tag> : '-'}
          <span style={{ fontSize: 13 }}>{match ? match[2] : '(0)'}</span>
        </Space>
      </Space>
    );
  };

  const handleSearch = inputValue => {
    const searchResult = crawlingList?.filter(app => {
      const pattern = /[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]/g;
      const regex = new RegExp(inputValue.replace(pattern, '').split('').join('.*'), 'i');
      return regex.test(app.title) || regex.test(app.subTitle);
    });
    setDataSource(searchResult);
  };

  // Table APIs
  const pagination = {
    size: 'large',
    pageSize: 10,
    current: pageNum ? parseInt(pageNum) : currentPage,
    position: ['bottomCenter'],
    showSizeChanger: false,
    onChange: page => (pageNum ? navigate(`/steam/${type}/${page}?date=${params}`) : setCurrentPage(page)),
  };

  const columns = [
    {
      key: 'numero',
      title: 'No',
      align: 'center',
      width: 50,
      render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: '일시',
      dataIndex: 'scrapingDate',
      align: 'center',
      sortDirections: ['ascend'],
      sorter: (a, b) => new Date(a.crawlingDate) - new Date(b.crawlingDate),
      render: date => (
        <Space direction="vertical" size={0}>
          {convertDateFormat(date, 'YYYY-MM-DD')}
          {convertDateFormat(date, 'HH:mm')}
        </Space>
      ),
    },
    {
      title: '기본 정보',
      align: 'center',
      children: [
        {
          title: '타이틀',
          dataIndex: 'title',
          align: 'center',
          width: 200,
        },
        {
          title: '서브 타이틀',
          dataIndex: 'subTitle',
          align: 'center',
          width: 100,
          render: subTitle => subTitle ?? '-',
        },
        {
          title: '썸네일',
          dataIndex: 'imagePath',
          align: 'center',
          render: imagePath => (
            <Image src={`${process.env.REACT_APP_BASE_URL}/${imagePath}`} width={150} height={70} placeholder={<ImagePlaceHolder />} />
          ),
        },
        {
          title: '출시일',
          dataIndex: 'releaseDate',
          align: 'center',
          render: releaseDate => convertDateFormat(releaseDate, 'YYYY-MM-DD'),
        },
        {
          title: '개발사',
          dataIndex: 'developer',
          align: 'center',
        },
        {
          key: 'publisher',
          title: '퍼블리싱',
          align: 'center',
          className: 'td-white-space-pre',
          render: (_, record) => [record.publisher1, record.publisher2].filter(publisher => publisher !== null).join('\n'),
        },
        {
          title: '기기',
          dataIndex: 'device',
          align: 'center',
          width: 50,
        },
        {
          title: '가격',
          dataIndex: 'price',
          align: 'center',
          width: 50,
          render: price => (price ? new IntlFormatter().getPriceByCountry(price) : 'Free'),
        },
      ],
    },
    {
      title: '평가',
      align: 'center',
      children: [
        {
          title: '최근 리뷰',
          dataIndex: 'recentReviews',
          align: 'center',
          render: recentReviews => getRateUI(recentReviews),
        },
        {
          title: '전체 리뷰',
          dataIndex: 'allReviews',
          align: 'center',
          render: allReviews => getRateUI(allReviews),
        },
      ],
    },
    {
      title: '인기 사용자 태그',
      align: 'center',
      render: (_, record) => {
        const tags = [];
        for (let i = 0; i < 5; i++) {
          tags.push(record[`popularUserTag${i + 1}`]);
        }
        return tags.map(tag => <Tag key={tag}>{tag}</Tag>);
      },
    },
    {
      title: '사용자 리뷰 통계',
      align: 'center',
      children: [
        {
          title: '추천',
          dataIndex: 'recommended',
          align: 'center',
          width: 100,
          render: recommended => <IconText Icon={LikeFilled} text={recommended.toLocaleString()} />,
        },
        {
          title: '비추천',
          dataIndex: 'notRecommended',
          align: 'center',
          width: 100,
          render: notRecommended => <IconText Icon={DislikeFilled} text={notRecommended.toLocaleString()} />,
        },
        {
          title: '총 플레이 시간',
          dataIndex: 'totalPlayTime',
          align: 'center',
          width: 120,
        },
        {
          title: '리뷰 플레이 시간',
          dataIndex: 'atReviewTime',
          align: 'center',
          width: 120,
        },
        {
          title: '어워드',
          dataIndex: 'feedbackCount',
          align: 'center',
          width: 100,
          render: feedbackCount => <IconText Icon={CrownFilled} text={feedbackCount.toLocaleString()} />,
        },
      ],
    },
    {
      title: '최신 업데이트 정보',
      align: 'center',
      children: [
        {
          title: '제목',
          dataIndex: 'updateTitle',
          align: 'center',
          width: 300,
        },
        {
          title: '카테고리',
          dataIndex: 'updateCategory',
          align: 'center',
          render: category => <Tag color={getTagColor(category)}>{category}</Tag>,
        },
        {
          title: '업데이트 날짜',
          dataIndex: 'updateDate',
          align: 'center',
          render: updateDate => convertDateFormat(updateDate, 'YYYY-MM-DD') ?? <Text type="secondary">확인 필요</Text>,
        },
        {
          title: '내용 요약',
          dataIndex: 'updateDescription',
          align: 'center',
          width: 200,
          render: description =>
            description ? (
              <Popover content={description} trigger="click" placement="left" overlayInnerStyle={{ maxWidth: 400 }}>
                <Button type="text" size="small">
                  <Flex justify="center" align="center" gap={4}>
                    <span>{description.trim().slice(0, 30)}...</span>
                    <SearchOutlined style={{ fontSize: 16 }} />
                  </Flex>
                </Button>
              </Popover>
            ) : (
              '-'
            ),
        },
        {
          title: '좋아요',
          dataIndex: 'likeCount',
          align: 'center',
          width: 80,
          render: likeCount => <IconText Icon={LikeFilled} text={likeCount.toLocaleString()} />,
        },
        {
          title: '댓글',
          dataIndex: 'replyCount',
          align: 'center',
          width: 80,
          render: replyCount => <IconText Icon={MessageFilled} text={replyCount.toLocaleString()} />,
        },
      ],
    },
  ];

  if (type === 'daily')
    columns.push({
      key: 'all',
      title: '전체 데이터',
      align: 'center',
      fixed: 'right',
      render: record => (
        <Link to={`/steam/app/details/${record.appID}`} state={record}>
          <Flex justify="center" align="center" gap={2}>
            <span>보러 가기</span>
            <RightOutlined />
          </Flex>
        </Link>
      ),
    });

  return (
    <Flex vertical gap="middle">
      <Flex justify="space-between">
        <Badge count={dataSource?.length} showZero style={{ zIndex: 10 }}>
          <Input.Search placeholder="게임명으로 검색" onSearch={handleSearch} disabled={type === 'app'} enterButton allowClear />
        </Badge>
        <ExcelButton type="steam" dataSource={dataSource} fileName={`Steam${appID ? `_${appID}` : ''}`} />
      </Flex>
      <Table
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey={record => record.recordID}
        pagination={pagination}
        loading={{ spinning: isLoading, tip: '로딩 중입니다...' }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  );
}

function IconText({ Icon, text }) {
  return (
    <Flex justify="center" align="center" gap={4}>
      <Icon style={{ fontSize: 16 }} />
      <span>{text}</span>
    </Flex>
  );
}
