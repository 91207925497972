import axios from 'axios';

export default class User {
  #client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  status = async () => {
    return this.#client
      .get('/user')
      .then(response => response.data)
      .catch(error => console.error(error.message));
  };

  signUp = async user => {
    return this.#client
      .post('/user/signup', user)
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };

  checkEmail = async email => {
    return this.#client
      .post('/user/check/email', email)
      .then(response => response.data.emailExists)
      .catch(error => console.error(error.message));
  };

  checkPassword = async passwordHash => {
    return this.#client
      .post('/user/check/password', passwordHash)
      .then(response => response.data.isPasswordMatch)
      .catch(error => console.error(error.message));
  };

  confirmEmail = async user => {
    return this.#client
      .post('/user/signup/confirm', user)
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };

  sendToken = async email => {
    return this.#client
      .post('/user/signup/token', email)
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };

  sendTempPassword = async email => {
    return this.#client
      .post('/user/help', email)
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };

  changePassword = async password => {
    return this.#client
      .post('/user/change/password', password)
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };

  signIn = async user => {
    return this.#client
      .post('/user/signin', user)
      .then(response => response.data)
      .catch(error => console.error(error.message));
  };

  signOut = async () => {
    return this.#client
      .post('/user/signout')
      .then(response => response.data.success)
      .catch(error => console.error(error.message));
  };
}
