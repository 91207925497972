import { App, Flex, Form, Input, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { CardContainer } from '../styled';
import Auth from '../api/auth';
import SubmitButton from '../components/SubmitButton';
import { useSubmit } from 'react-router-dom';

export default function Password() {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const { Paragraph, Title } = Typography;
  const auth = new Auth();
  const submit = useSubmit();

  const verifyPassword = (_, value) => {
    if (form.getFieldValue('newPassword') === value) return Promise.resolve();
    return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
  };

  const rules = {
    password: [{ required: true }, { whitespace: true }, { min: 6 }],
    verify: [{ required: true, message: '비밀번호가 일치하지 않습니다.' }, { validator: verifyPassword }],
  };

  const handleFinish = values => {
    modal.confirm({
      content: '비밀번호를 변경하시겠습니까?',
      onOk: async () => {
        const isPasswordMatch = await auth.checkPassword({ passwordHash: values.currentPassword });
        if (!isPasswordMatch) return modal.error({ content: '현재 비밀번호가 일치하지 않습니다.' });
        const changePasswordSuccess = await auth.changePassword({ ...values });
        if (!changePasswordSuccess) return modal.error({ content: '비밀번호 변경 중 오류가 발생했습니다.' });

        // 비밀번호 변경 성공
        modal.success({
          content: '비밀번호가 변경되었습니다. 새로운 비밀번호로 다시 로그인해 주세요.',
          afterClose: () => {
            const submitTarget = { queryKey: ['isSignedIn'] };
            const submitOptions = { method: 'post', action: '/', encType: 'application/json' };
            submit(submitTarget, submitOptions);
          },
        });
      },
    });
  };

  return (
    <Flex vertical justify="center" align="center" style={{ height: '100%' }}>
      <Title level={2}>비밀번호 변경</Title>
      <CardContainer width={480}>
        <Form form={form} autoComplete="off" onFinish={handleFinish}>
          <Paragraph>현재 비밀번호를 입력해 주세요.</Paragraph>
          <Form.Item name="currentPassword" rules={rules.password} messageVariables={{ label: '현재 비밀번호' }} validateFirst hasFeedback>
            <Input.Password type="password" placeholder="현재 비밀번호" prefix={<LockOutlined />} allowClear />
          </Form.Item>
          <Paragraph>변경할 비밀번호를 입력해 주세요.</Paragraph>
          <Form.Item name="newPassword" rules={rules.password} messageVariables={{ label: '변경할 비밀번호' }} validateFirst hasFeedback>
            <Input.Password type="password" placeholder="변경할 비밀번호" prefix={<LockOutlined />} allowClear />
          </Form.Item>
          <Form.Item name="verify" dependencies={['newPassword']} rules={rules.verify} validateFirst hasFeedback>
            <Input.Password type="password" placeholder="비밀번호 확인" prefix={<LockOutlined />} allowClear />
          </Form.Item>
          <Form.Item shouldUpdate>{() => <SubmitButton form={form}>확인</SubmitButton>}</Form.Item>
        </Form>
      </CardContainer>
    </Flex>
  );
}
