import { NavLink, Outlet, useLocation, useNavigate, useRouteLoaderData, useSubmit } from 'react-router-dom';
import { App, Button, Dropdown, Flex, Layout, Menu, Typography } from 'antd';
import { AppstoreFilled, DownOutlined, HomeFilled, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { BsDiscord, BsMeta, BsSteam } from 'react-icons/bs';
import { flexBetween } from '../styled';
import styled from 'styled-components';
import Auth from '../api/auth';

const Header = styled(Layout.Header)`
  ${flexBetween}
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: var(--header-padding-size);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
`;
const Content = styled(Layout.Content)`
  padding: var(--content-padding-size);
  overflow: auto;
`;
const MenuList = styled(Menu)`
  height: 100%;
  padding: 0 16px;
`;

export default function MainLayout() {
  const path = useLocation().pathname;
  const { username } = useRouteLoaderData('root');
  const { modal } = App.useApp();
  const { Text } = Typography;

  const submit = useSubmit();
  const navigate = useNavigate();

  const handleSignOut = () => {
    modal.confirm({
      icon: null,
      content: '로그아웃하시겠습니까?',
      onOk: async () => {
        const auth = new Auth();
        const success = await auth.signOut();
        const submitTarget = { queryKey: ['isSignedIn'] };
        const submitOptions = { method: 'post', action: '/', encType: 'application/json' };
        success ? submit(submitTarget, submitOptions) : modal.error({ content: '로그아웃 도중 오류가 발생했습니다.' });
      },
    });
  };

  const siderMenu = [
    {
      key: '/',
      icon: <HomeFilled />,
      label: <NavLink to="/">홈</NavLink>,
    },
    {
      type: 'divider',
    },
    {
      type: 'group',
      label: <Text strong>크롤링</Text>,
      children: [
        {
          key: '/register',
          icon: <AppstoreFilled />,
          label: <NavLink to="/register">App</NavLink>,
        },
        {
          key: '/steam',
          icon: <BsSteam />,
          label: <NavLink to="/steam/app/1">Steam</NavLink>,
        },
        {
          key: '/discord',
          icon: <BsDiscord />,
          label: <NavLink to="/discord/1">Discord</NavLink>,
        },
        {
          key: '/meta',
          icon: <BsMeta />,
          label: <NavLink to="/meta/1">Meta</NavLink>,
        },
      ],
    },
  ];

  const items = [
    {
      key: 'password',
      label: '비밀번호 변경',
      icon: <LockOutlined />,
      onClick: () => navigate('/password'),
    },
    {
      key: 'signOut',
      label: '로그아웃',
      icon: <LogoutOutlined />,
      onClick: handleSignOut,
    },
  ];

  const defaultSelectedKeys = (siderMenu.flatMap(menu => menu.children || []).find(children => path.startsWith(children.key)) || siderMenu[0]).key;

  return (
    <Layout>
      <Header>
        <img src="/images/logo.png" alt="STOIC ENTERTAINMENT" width={100} style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
        <Flex justify="center" align="center" gap="small">
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button type="text">
              <Flex justify="center" align="center" gap="small">
                <Text>{username}님</Text>
                <DownOutlined />
              </Flex>
            </Button>
          </Dropdown>
        </Flex>
      </Header>
      <Layout hasSider>
        <Layout.Sider width={240} breakpoint="xl" collapsedWidth={0}>
          <MenuList mode="inline" items={siderMenu} defaultSelectedKeys={defaultSelectedKeys} />
        </Layout.Sider>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
