import axios from 'axios';

export default class DiscordExporter {
  #client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/discord',
    withCredentials: true,
  });

  getJson = async guildId => {
    return this.#client //
      .get(`/${guildId}`)
      .then(response => response.data)
      .catch(error => console.error(`Failed to get json file: ${error.message}`));
  };
}
