import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default function Graph({ dataSource = [] }) {
  useLayoutEffect(() => {
    // Create root
    const root = am5.Root.new('discord-monthly-reaction');

    // Create theme
    const theme = am5.Theme.new(root);
    theme.rule('Label').setAll({
      fontFamily: 'NanumSquareNeo-Variable',
    });

    // Disabled logo
    root._logo.dispose();

    // Set themes
    root.setThemes([am5themes_Animated.new(root), theme]);

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.horizontalLayout,
      })
    );

    // Hide zoom-out button from appear while animating
    chart.zoomOutButton.set('forceHidden', true);

    // Add cursor
    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.grid.template.setAll({ location: 1 });
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'month',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0.1,
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: '디스코드 유저 반응 (월 평균)',
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: 'month',
        valueYField: 'average',
        minHeight: 0,
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: '{valueY}',
          centerY: am5.percent(80),
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });

    // Make each column to be of a different color
    series.columns.template.adapters.add('fill', (_, target) => chart.get('colors').getIndex(series.columns.indexOf(target)));

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'categoryX',
        y: am5.p50,
        centerY: am5.p50,
        marginLeft: 50,
        layout: am5.GridLayout.new(root, {
          maxColumns: dataSource.length > 12 ? 2 : 1,
          fixedWidthGrid: true,
        }),
      })
    );

    // Set Data
    xAxis.data.setAll(dataSource);
    series.data.setAll(dataSource);
    legend.data.setAll(series.dataItems);

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);

    return () => root.dispose();
  }, [dataSource]);

  return <div id="discord-monthly-reaction" style={{ width: '100%', height: 500, marginTop: 20 }}></div>;
}
