import { convertDateFormat } from '../utils/date';

export default class DiscordChat {
  #guild;
  #channel;
  #dateRange;
  #exportedAt;
  #messageCount;
  #messages;

  constructor(chat = []) {
    this.#guild = chat.guild || {};
    this.#channel = chat.channel || {};
    this.#dateRange = chat.dateRange || {};
    this.#exportedAt = chat.exportedAt || '';
    this.#messageCount = chat.messageCount || 0;
    this.#messages = chat.messages || [];
  }

  get guild() {
    return { ...this.#guild };
  }

  get channel() {
    return { ...this.#channel };
  }

  get messages() {
    return this.#messages
      .flatMap(message => [
        {
          messageID: message.id,
          content: message.content,
          reactions: message.reactions.map(reaction => ({ ...reaction.emoji, count: reaction.count })),
          timestamp: convertDateFormat(message.timestamp, 'YYYY-MM-DD'),
        },
      ])
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  }

  get daily() {
    const groupedByDate = {};

    this.#messages.forEach(message => {
      const date = convertDateFormat(message.timestamp, 'YYYY-MM-DD');
      if (!groupedByDate[date]) groupedByDate[date] = { date, reactionCount: 0 };
      groupedByDate[date].reactionCount += message.reactions.reduce((total, reaction) => total + reaction.count, 0);
    });

    return Object.values(groupedByDate);
  }

  get monthly() {
    const groupedByMonth = {};

    this.daily.forEach(message => {
      const month = convertDateFormat(message.date, 'YYYY-MM');
      if (!groupedByMonth[month]) groupedByMonth[month] = { month, messages: [], reactionCount: 0 };

      groupedByMonth[month].messages.push(message);
      groupedByMonth[month].reactionCount += message.reactionCount;
    });

    return Object.values(groupedByMonth).map(item => ({
      ...item,
      average: Math.round(item.reactionCount / item.messages.length),
    }));
  }

  get yearly() {
    const groupedByYear = {};

    this.monthly.forEach(message => {
      const year = convertDateFormat(message.month, 'YYYY');
      if (!groupedByYear[year]) groupedByYear[year] = { year, messages: [], reactionCount: 0 };

      groupedByYear[year].messages.push(message);
      groupedByYear[year].reactionCount += message.reactionCount;
    });

    return Object.values(groupedByYear).map(item => ({
      ...item,
      average: Math.round(item.reactionCount / item.messages.length),
    }));
  }

  get emojis() {
    return this.#messages
      .flatMap(message => message.reactions)
      .map(reaction => ({ ...reaction.emoji, count: reaction.count }))
      .reduce((result, currentEmoji) => {
        const existingEmoji = result.find(emoji => emoji.name === currentEmoji.name);
        existingEmoji ? (existingEmoji.count += currentEmoji.count) : result.push(currentEmoji);
        return result;
      }, [])
      .sort((a, b) => b.count - a.count)
      .slice(0, 20);
  }
}
