import { Navigate, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { authQuery } from '../Root';
import { ResultPage } from '../styled';

export default function AuthRoutes({ access }) {
  const {
    data: { isSignedIn },
  } = useQuery(authQuery());

  switch (access) {
    case 'public':
      return isSignedIn ? <Navigate to="/" /> : <Outlet />;
    case 'private':
      return isSignedIn ? <Outlet /> : <Navigate to="/signin" />;
    default:
      return <ResultPage status="404" title="페이지를 찾을 수 없습니다." />;
  }
}
