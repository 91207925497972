import { App, Flex, Form, Input, Layout, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CardContainer } from '../styled';
import Auth from '../api/auth';
import SubmitButton from '../components/SubmitButton';

export default function Help() {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const { Paragraph, Title } = Typography;
  const navigate = useNavigate();

  const rules = [
    { required: true },
    { whitespace: true },
    { type: 'email' },
    { validator: (_, value) => (value.includes('stoicent.com') ? Promise.resolve() : Promise.reject(new Error('회사 이메일만 입력 가능합니다.'))) },
  ];

  const handleFinish = ({ email }) => {
    const auth = new Auth();
    modal.confirm({
      content: '입력하신 이메일 주소로 임시 비밀번호가 발급됩니다. 계속 진행하시겠습니까?',
      onOk: async () => {
        const success = await auth.sendTempPassword({ email });
        if (!success) return modal.error({ content: '임시 비밀번호가 발급에 실패했습니다.' });
        modal.success({ content: '임시 비밀번호가 발급되었습니다.' });
        navigate('/signin', { replace: true });
      },
    });
  };

  return (
    <Layout>
      <Flex vertical justify="center" align="center" style={{ height: '100%' }}>
        <Title level={2}>비밀번호 찾기</Title>
        <CardContainer width={480}>
          <Paragraph>비밀번호를 찾고자하는 이메일 주소를 입력해 주세요.</Paragraph>
          <Form form={form} autoComplete="off" onFinish={handleFinish}>
            <Form.Item name="email" rules={rules} messageVariables={{ label: '이메일 주소' }} validateFirst>
              <Input placeholder="이메일" prefix={<MailOutlined />} allowClear />
            </Form.Item>
            <Form.Item shouldUpdate>{() => <SubmitButton form={form}>확인</SubmitButton>}</Form.Item>
          </Form>
        </CardContainer>
      </Flex>
    </Layout>
  );
}
